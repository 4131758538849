import React, { useRef } from 'react'
import Layout from '../layouts'
import SEO from '../components/seo'
import Image from '../components/image'
import Intro from '../components/intro'
import Content from '../components/content'
import Info from '../components/info'
import Block from '../components/block'
import { animated } from 'react-spring'
import useIsInViewport from 'use-is-in-viewport'
import useAnimate from '../hooks/useAnimate'
import P from '../components/paragraph'

const AnimatedImage = animated(Image)
const AnimatedBlock = animated(Block)

const Venue = () => {
  const fadeIn = useAnimate()
  const travelRef = useRef(null)
  const [isInViewport, wrappedRef] = useIsInViewport({
    target: travelRef,
    modBottom: '-10%'
  })
  const fadeInTrigger = useAnimate({ trigger: isInViewport })

  return (
    <Layout>
      <SEO title="Venue &amp; Travel" />
      <Intro>
        <h1>Venue &amp; Travel</h1>
        <P narrow>
          Our venue will be in Old Thorns hotel, located in Liphook, Hampshire.
          We will be getting married in the Hampshire Suite which is located near the main entrance.
        </P>
        <P narrow>
          Please see map below for further guidance
        </P>
      </Intro>
      <AnimatedImage style={fadeIn(250)} height={350} file="dorsetHouse" />
      <Content css="margin-top: 2em;" columns>
        <AnimatedBlock style={fadeIn(500)}>
          <h3>Directions</h3>
          <Info>
            <strong>Old Thorns Hotel &amp; Resort</strong>
            <br />
            Longmoor Road
            <br />
            Liphook 
            <br />
            GU30 7PE
          </Info>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/maps/dir//Old+Thorns+Hotel+%26+Resort,+Griggs+Green,+Liphook/@51.0748668,-0.8734332,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x487434192f3977d3:0x14a3d3b0e5165544!2m2!1d-0.832234!2d51.074874!3e2?entry=ttu"
          >
            Get directions &rarr;
          </a>
        </AnimatedBlock>
        <AnimatedBlock style={fadeIn(750)}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.8199339558064!2d-0.83480892263732!3d51.074873971718205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487434192f3977d3%3A0x14a3d3b0e5165544!2sOld%20Thorns%20Hotel%20%26%20Resort!5e0!3m2!1sen!2suk!4v1705852576926!5m2!1sen!2suk"
            width="600"
            height="450"
            frameBorder="0"
            style={{
              border: 0,
              width: '100%',
              height: '350px'
            }}
            allowFullScreen
          />
        </AnimatedBlock>
      </Content>
      <Content narrow>
        <AnimatedBlock style={fadeInTrigger()}>
          <h3 ref={wrappedRef}>Car Parking &amp; Taxis</h3>
          <p>
            Parking is available on site, please book taxis in advance if required. 
          </p>
          <p>
            Guests that are not staying at the hotel will need to leave by midnight.
          </p>
        </AnimatedBlock>
      </Content>
    </Layout>
  )
}

export default Venue
